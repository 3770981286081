import { useEffect, useState } from 'react';
import { FilterMenu } from 'client/components/Gallery/Header/FilterMenu';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { FavoritesCTA } from 'client/components/Gallery/Header/Favorites';
import { useAuth } from 'client/hooks/useAuth';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { SearchButton } from 'client/components/Gallery/Header/SearchButton';
import { WarningBox } from 'client/components/Gallery/Header/WarningBox';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { PERSONALIZATION_UX_MOBILE_VARIATIONS } from '~/experiments/tilePersonalization/constants';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { MobilePersonalization } from '~/client/components/Gallery/Header/Personalization/MobilePersonalization';

export const StaticHeader = (): JSX.Element => {
    const showFavorites = useFavorites();
    const auth = useAuth();
    const isExperimentActive = useExperimentation();
    const mobilePersonalizationExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const [isMobilePersonalizationEnabled, setMobilePersonalizationEnabled] = useState(false);

    useEffect(() => {
        setMobilePersonalizationEnabled(isSmallScreen() && mobilePersonalizationExperimentEnabled);

        if (!mobilePersonalizationExperimentEnabled && isSmallScreen()) {
            isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Control);
        }
    }, [isExperimentActive, mobilePersonalizationExperimentEnabled]);

    const shouldShowFavorites = showFavorites && auth;

    return (
        <div className="header-wrapper">
            <div className="subheader-actions">
                <SearchBox />
                <SearchButton shouldHideText={mobilePersonalizationExperimentEnabled} />
                {shouldShowFavorites && (<FavoritesCTA className="mobile-rounded-icon-button" userInteractionLocation="staticHeader" />)}
                <FilterMenu shouldRenderMobilePersonalizationFilter={isMobilePersonalizationEnabled} />
                {isMobilePersonalizationEnabled && <MobilePersonalization />}
            </div>
            <WarningBox />
        </div>
    );
};

StaticHeader.displayName = 'StaticHeader';
