import { createSelector } from 'reselect';
import qs from 'query-string';

import {
    categoryRefinementsSelector,
    nonDefaultAttributeRefinementIdsSelector,
    keywordRefinementSelector,
    collectionRefinementSelector,
    templateUseCaseRefinementsSelector,
} from 'client/store/refinement/selectors';
import { nonDefaultPageSelector, nonDefaultPageSizeSelector } from 'client/store/paging/selectors';
import { updateQuery } from 'client/store/locationState/utils';
import { getMpvid, getQuantitySelector } from 'client/store/config';
import {
    getNoCache,
    getBypassApproval,
    getUseConstraints,
    getRankingStrategyOverride,
    getForcedRankingStrategyOverride,
    getQuickViewId,
    getEnrich,
    getIsProduct,
    getExperienceType,
    getTemplatePurposes,
} from 'client/store/debug';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import {
    getImagePlaceholderAspectRatio, getImagePlaceholderAspectRatioTolerance,
    getPlaceholderPurposes, getUseAlternateSearchProvider, getSearchBackend,
    getDesignCreationTypes,
    getUseRealisationEngineService,
    getEnableTemplateUseCases,
} from 'client/store/debug/reducer';

export const selectQueryState = createSelector(
    nonDefaultAttributeRefinementIdsSelector,
    categoryRefinementsSelector,
    templateUseCaseRefinementsSelector,
    keywordRefinementSelector,
    collectionRefinementSelector,
    nonDefaultPageSelector,
    nonDefaultPageSizeSelector,
    (
        attributeIds,
        categories,
        templateUseCases,
        keyword,
        collection,
        page: number | null,
        pageSize: number | null,
    ): Gallery.Models.Url.SelectQueryState => ({
        parameters: {
            page,
            pageSize,
            [REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL]: attributeIds.map((attr) => attr),
            [REFINEMENT_DIMENSION.CATEGORY_PLURAL]: categories.map((attr) => attr.value),
            [REFINEMENT_DIMENSION.TEMPLATE_USE_CASES]: templateUseCases.map((uc) => uc.value),
            [REFINEMENT_DIMENSION.KEYWORD]: keyword ? keyword.value : null,
            [REFINEMENT_DIMENSION.COLLECTION]: collection ? collection.value : null,
        },
        replace: keyword && !!keyword.replace,
    }),
);

export function selectQueryString(newQueryState: Gallery.Models.Url.SelectQueryState, queryString: string): string {
    const query = { ...qs.parse(queryString) };

    for (const [key, value] of Object.entries(newQueryState.parameters)) {
        updateQuery(key, value, query);
    }
    return Object.keys(query).length
        ? `?${qs.stringify(query, { arrayFormat: 'comma' })}`
        : '';
}

export const selectFullQueryStringState = createSelector(
    nonDefaultAttributeRefinementIdsSelector,
    categoryRefinementsSelector,
    templateUseCaseRefinementsSelector,
    keywordRefinementSelector,
    collectionRefinementSelector,
    nonDefaultPageSelector,
    nonDefaultPageSizeSelector,
    getQuantitySelector,
    getMpvid,
    getNoCache,
    getUseConstraints,
    getBypassApproval,
    getRankingStrategyOverride,
    getForcedRankingStrategyOverride,
    getQuickViewId,
    getUseRealisationEngineService,
    getEnrich,
    getIsProduct,
    getExperienceType,
    getTemplatePurposes,
    getImagePlaceholderAspectRatio,
    getImagePlaceholderAspectRatioTolerance,
    getPlaceholderPurposes,
    getUseAlternateSearchProvider,
    getSearchBackend,
    getDesignCreationTypes,
    getEnableTemplateUseCases,
    (
        attributeIds,
        categories,
        templateUseCases,
        keyword,
        collection,
        pageParam,
        pageSizeParam,
        quantityParam,
        mpvIdParam,
        noCacheParam,
        useConstraintsParam,
        bypassApprovalParam,
        rankingStrategyParam,
        forcedRankingStrategyParam,
        quickViewId,
        useRealisationEngineService,
        enrich,
        isProduct,
        experienceType,
        templatePurposes,
        imagePlaceholderAspectRatio,
        imagePlaceholderAspectRatioTolerance,
        placeholderPurposes,
        useAlternateSearchProvider,
        searchBackend,
        designCreationTypes,
        enableTemplateUseCases,
    ): Gallery.Models.Url.ParamsQueryState => ({
        bypassApproval: bypassApprovalParam || undefined,
        mpvId: mpvIdParam || undefined,
        noCache: noCacheParam || undefined,
        useConstraints: useConstraintsParam || undefined,
        page: pageParam || undefined,
        pageSize: pageSizeParam || undefined,
        quantity: quantityParam || undefined,
        [REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL]: attributeIds.length ? attributeIds.map((attr) => attr) : undefined,
        [REFINEMENT_DIMENSION.CATEGORY_PLURAL]: categories.length ? categories.map((attr) => attr.value) : undefined,
        [REFINEMENT_DIMENSION.TEMPLATE_USE_CASES]: templateUseCases.length
            ? templateUseCases.map((uc) => uc.value) : undefined,
        [REFINEMENT_DIMENSION.KEYWORD]: keyword ? keyword.value : undefined,
        [REFINEMENT_DIMENSION.COLLECTION]: collection ? collection.value : undefined,
        rankingStrategyOverride: rankingStrategyParam || undefined,
        forcedRankingStrategyOverride: forcedRankingStrategyParam || undefined,
        quickView: quickViewId || undefined,
        enrich: enrich || undefined,
        isProduct: isProduct || undefined,
        experienceType: experienceType || undefined,
        templatePurposes,
        imagePlaceholderAspectRatio: imagePlaceholderAspectRatio || undefined,
        imagePlaceholderAspectRatioTolerance: imagePlaceholderAspectRatioTolerance || undefined,
        placeholderPurposes: placeholderPurposes || undefined,
        useAlternateSearchProvider: useAlternateSearchProvider || undefined,
        useRealisationEngineService: useRealisationEngineService || undefined,
        searchBackend: searchBackend || undefined,
        source: searchBackend || undefined,
        designCreationTypes: designCreationTypes || undefined,
        enableTemplateUseCases: enableTemplateUseCases || undefined,
    }),
);
