import config from 'config';
import classnames from 'classnames';
import {
    Typography, Button, Link, LinkProps, Icon,
} from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { ComponentProps } from 'react';
import { getLocaleSelector } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from 'shared/constants';
import { GalleryUrlService } from 'services/GalleryUrlService';
import { getProductKey } from 'client/store/config';
import { SELECTED_PRODUCT_QSP } from 'client/constants';
import { getRawExperiments } from '~/client/store/experimentation';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME, PERSONALIZATION_UX_MOBILE_VARIATIONS } from '~/experiments/tilePersonalization/constants';
import { trackExperimentEngagement } from '~/shared/ab-testing';

export type PropTypes = Gallery.Analytics.ButtonLocationProp & ComponentProps<typeof Button>;

export const FavoritesCTA = (props: PropTypes): JSX.Element => {
    const { className, userInteractionLocation, ...rest } = props;
    const localize = useTranslations();
    const locale = useSelector(getLocaleSelector);
    const analytics = useAnalytics();
    const productKey = useSelector(getProductKey).toUpperCase();
    const favoritesPageUrl = GalleryUrlService.resolveUrl(
        new URL(`${window.location.origin}/${locale}/f?${SELECTED_PRODUCT_QSP}=${productKey}`),
        locale,
    ).href;

    // hooks added for temporary Personalization UX experiment tracking
    const isExperimentActive = useExperimentation();
    const rawExperiments = useSelector(getRawExperiments);

    const onClick = (): void => {
        analytics.trackEvent({
            userInteractionLocation,
            action: ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
            eventLabel: ANALYTICS_LABEL.FAVORITES_CTA,
            route: config.client.segmentRoute,
        });

        if (isSmallScreen()) {
            if (
                isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)
            ) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_MOBILE_VARIATIONS.Control,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
                    'Favorites Button',
                    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                    ANALYTICS_LABEL.FAVORITES_CTA,
                );
            } else if (isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
                    'Favorites Button',
                    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
                    ANALYTICS_LABEL.FAVORITES_CTA,
                );
            }
        }

        window.location.href = favoritesPageUrl;
    };

    return (
        <Button
            {...rest}
            buttonShape="round"
            className={classnames('favorites-cta-button', className)}
            component="a"
            iconPosition="left"
            render={(linkProps: LinkProps): JSX.Element => (
                <Link
                    className={linkProps.className}
                    href={favoritesPageUrl}
                    onClick={onClick}
                >
                    {linkProps.children}
                </Link>
            )}
        >
            <Icon iconType="heartEmpty" />
            <Typography as="span" className="favorites-cta-text" fontSize="small" fontWeight="bold">{localize('Favorites')}</Typography>
        </Button>
    );
};

FavoritesCTA.displayName = 'FavoritesCTA';
