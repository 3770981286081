import {
    Box,
    Button,
    Divider,
    FormField,
    FormInputGroup,
    Spinner,
    StandardForm,
    TextInput,
    TextInputFloatingLabel,
    TextInputWithButtonInset,
    TextInputWithFloatingLabel,
} from '@vp/swan';
import { useDispatch, useSelector } from 'react-redux';
import {
    useState, useMemo, useEffect,
    useCallback,
} from 'react';
import { useAuth } from '~/client/hooks/useAuth';
import {
    UploadConfigurationProvider, UploadManagerProvider, UploadManagerProviderProps,
    basicImageFileTypeMappings,
} from '@design-stack-vista/upload-components';
import { SubBrands, Tenants, VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { getLogger } from '~/client/utils/gallery/logger';
import { convertVistaAssetToPhotoPreviewData } from 'client/components/Gallery/Header/Personalization/utils';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import {
    PERSONALIZATION_UX_EXPERIMENT_NAME,
    PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
    PERSONALIZATION_UX_MOBILE_VARIATIONS,
    PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME,
    PERSONALIZATION_UX_PHASE_2_VARIATIONS,
    PERSONALIZATION_UX_VARIATIONS,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
} from '~/experiments/tilePersonalization/constants';
import { getRawExperiments } from '~/client/store/experimentation';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { useBrandContext } from '@vp/react-brand';
import { RenderProperty } from 'shared/renderProperties';
import { useTranslations } from '~/client/hooks/useTranslations';
import { scrollToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import {
    customImageDataUpdate, logoAppliedUpdate, designPersonalizationContextUpdate, personalizedTextUpdate,
} from 'client/store/personalization/actions';
import { designPersonalizationContextSelector, photoPreviewDataSelector } from 'client/store/personalization/selectors';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { photoUploadState } from 'client/atoms/photoUploadStateAtom';
import { contentUpdate } from 'client/store/content';
import {
    LOCAL_STORAGE_KEYS, MAX_PHOTO_UPLOADS, PHOTO_UPLOAD_STATES,
} from 'client/constants';
import { shouldRestorePhotosState } from '~/client/atoms/shouldRestorePhotosAtom';
import { splitFullName } from './splitFullName';
import { dpcToBrandContent, dpcToTextFields } from './dpcConverters';
import { MAX_UPLOADS, PURPOSE_NAMES, TEXT_INPUT_DEBOUNCE_TIME_MS } from './constants';
import { TemplateField } from './purposeHelpers';
import { PersonalizationImageModal } from './PersonalizationImageModal';
import { PersonalizationImageUploadButton } from './PersonalizationImageUploadButton';
import { usePurposeNames } from '../../Header/Personalization/hooks/usePurposeNames';

interface PersonalizationFilterProps {
    mobileProps?: {
        isOpen: boolean,
        setIsOpen: (isOpen: boolean) => void,
        unappliedAsset: Gallery.Models.Personalization.PhotoPreviewData | undefined,
        setUnappliedAsset: (asset?: Gallery.Models.Personalization.PhotoPreviewData) => void,
        mobileTextfieldValues: Record<string, string>,
        setMobileTextfieldValues: (values: Record<string, string>) => void,
    }
}

export const PersonalizationFilter = (props: PersonalizationFilterProps): JSX.Element => {
    const { mobileProps } = props;
    const [textfieldValues, setTextfieldValues] = useState<Record<string, string>>({});
    const [textDebounceFns, setTextDebounceFns] = useState<Record<string, NodeJS.Timeout>>({});
    const dispatch = useDispatch();
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [textUpdateLoading, setTextUpdateLoading] = useState<Record<string, boolean>>({});
    const [experimentVariation, setExperimentVariation] = useState<string>(PERSONALIZATION_UX_VARIATIONS.Enabled);
    const [experimentName, setExperimentName] = useState(PERSONALIZATION_UX_EXPERIMENT_NAME);
    const [isError, setIsError] = useState(false);
    const auth = useAuth();
    const LOGGER = getLogger();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const isExperimentActive = useExperimentation();
    const brandcontext = useBrandContext();
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [brandId, setBrandId] = useState<string | undefined>();
    const { createBrand, updateBrand } = brandcontext.api;
    const localize = useTranslations();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const shouldStoreToBrand = booleanRenderProperty(RenderProperty.PersonalizationStoreToBrand);
    const purposeNames = usePurposeNames();
    const mobilePersonalizationExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const [isSmallScreenPersonalizationEnabled, setMobilePersonalizationEnabled] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useRecoilState(selectedPhotosState);
    const photoPreviewData = useSelector(photoPreviewDataSelector);
    const setPhotoUploadState = useSetRecoilState(photoUploadState);
    const [localUploadedAssets, setLocalUploadedAssets] = useState<VistaAsset[]>();
    // Used for persisting dpc data
    const galleryId = useSelector(getGalleryIdSelector);
    const localStorageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPC + galleryId;
    const setShouldRestorePhotos = useSetRecoilState(shouldRestorePhotosState);

    const dpc = useSelector(designPersonalizationContextSelector);
    const personalizedText = dpcToTextFields(purposeNames, dpc);
    // Temporary solution to personalization for mobile experiement getting restored in control.
    // Can be removed after mobile experiment is rolled out.
    const isClientSide = typeof window !== 'undefined';

    useEffect(() => {
        setMobilePersonalizationEnabled(isSmallScreen() && mobilePersonalizationExperimentEnabled);
    }, [mobilePersonalizationExperimentEnabled]);

    // Only on load get the stored dpc
    useEffect(() => {
        const localStorageData = localStorage.getItem(localStorageKey);

        if (!localStorageData || !isClientSide) {
            return;
        }
        const restoredDpc: Gallery.Models.Personalization.DesignPersonalizationContext = JSON.parse(localStorageData);

        // Restore local state
        setTextfieldValues(dpcToTextFields(purposeNames, restoredDpc));
        setShouldRestorePhotos(true);

        // Restore Redux state
        dispatch(designPersonalizationContextUpdate(restoredDpc));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientSide]);

    const createOrUpdateBrand = useCallback(async (): Promise<void> => {
        const brandContent = dpcToBrandContent(dpc);

        // Don't do anything if there is no content
        if (Object.keys(brandContent).length === 0) {
            return;
        }

        // Only create a brand if there isn't an existing one
        if (!brandId && brandcontext.brands.length === 0) {
            try {
                setIsLoadingBrand(true);
                const newBrand = await createBrand(brandContent);

                setBrandId(newBrand.brandId);
            } finally {
                setIsLoadingBrand(false);
            }
        } else if (brandId) {
            try {
                setIsLoadingBrand(true);
                // patchBrand will update only data specified in request
                // updateBrand will replace all data. TODO: Switch to patchBrand when it works
                updateBrand({
                    brandId,
                    isActive: true,
                    isFactual: true,
                    ...brandContent,
                });
            } finally {
                setIsLoadingBrand(false);
            }
        }
        // Including the brand functions here causes infinite network calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, dpc, brandcontext.isInitialized]);

    useEffect(() => {
        if (!shouldStoreToBrand || isLoadingBrand || !brandcontext.isInitialized) {
            return;
        }

        createOrUpdateBrand();
    }, [brandId, brandcontext.isInitialized, createOrUpdateBrand, isLoadingBrand, shouldStoreToBrand]);

    const updatePersonalizedText = useCallback((newTextInput: string, purposeName: PURPOSE_NAMES) => {
        if (newTextInput !== '') {
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                dispatch(personalizedTextUpdate({
                    ...personalizedText,
                    [purposeName]: newTextInput,
                    ...splitFullName(newTextInput),
                }, galleryId));
            } else {
                dispatch(personalizedTextUpdate({ ...personalizedText, [purposeName]: newTextInput }, galleryId));
            }
        } else {
            // Reset field (and derived fields) back to template placeholder
            // eslint-disable-next-line no-lonely-if
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                const {
                    fullname: _, firstname: _1, lastname: _2, ...newPersonalizedText
                } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            } else {
                const { [purposeName]: _, ...newPersonalizedText } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            }
        }

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Text Personalized',
            experimentName,
            `Text Personalized: ${purposeName}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Personalized',
            eventDetail: `Text Personalized: ${purposeName}`,
            ...analytics.getPageProperties(),

        });
    }, [analytics, dispatch, experimentName, experimentVariation, galleryId, personalizedText, rawExperiments]);

    const handleTextChange = (templateField: TemplateField, event: React.ChangeEvent<HTMLInputElement>): void => {
        const { purposeName } = templateField;
        const newText = event.target.value;

        if (isSmallScreen()) {
            mobileProps?.setMobileTextfieldValues({ ...mobileProps?.mobileTextfieldValues, [purposeName]: newText });
            return;
        }

        setTextfieldValues({ ...textfieldValues, [purposeName]: newText });

        const debounceFn = textDebounceFns[purposeName];

        if (debounceFn) {
            clearTimeout(debounceFn);
            setTextUpdateLoading((prevState) => ({
                ...prevState,
                [purposeName]: false,
            }));
        }

        setTextDebounceFns({
            ...textDebounceFns,
            [purposeName]: setTimeout(() => {
                updatePersonalizedText(newText, purposeName);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }, TEXT_INPUT_DEBOUNCE_TIME_MS),
        });
        setTextUpdateLoading((prevState) => ({
            ...prevState,
            [purposeName]: true,
        }));
    };

    const handleEnterPress = (purposeName: PURPOSE_NAMES, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && textfieldValues[purposeName] !== personalizedText[purposeName]) {
            trackExperimentEngagement(
                experimentVariation,
                rawExperiments,
                analytics,
                ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                'Text Personalized - Enter Key Submit',
                experimentName,
                `Text Personalized: ${purposeName}`,
            );
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Text Personalized - Enter Key Submit',
                eventDetail: `Text Personalized: ${purposeName}`,
                ...analytics.getPageProperties(),

            });

            if (textDebounceFns[purposeName]) {
                clearTimeout(textDebounceFns[purposeName]);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }

            updatePersonalizedText(textfieldValues[purposeName], purposeName);
        }
    };

    const trackTextFocus = (purposeName: string): void => {
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Text Field Selected',
            experimentName,
            `Text Field Selected: ${purposeName}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Field Selected',
            eventDetail: `Text Field Selected: ${purposeName}`,
            ...analytics.getPageProperties(),
        });
    };

    const uploadAuthProvider = useMemo(() => ({
        getAuthHeaders: async (): Promise<{ Authorization: string }> => ({ Authorization: `Bearer ${auth?.accessToken}` }),
    }), [auth]);

    // Only used by mobile
    const handleClearImageSelection = useCallback((): void => {
        mobileProps?.setUnappliedAsset(undefined);
    }, [mobileProps]);

    const handleUploadError = useCallback((error?: Error, uploadedAssets?: VistaAsset[]): void => {
        if (isSmallScreen()) {
            handleClearImageSelection();
        } else {
            setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.ERROR, message: error?.message });
        }
        LOGGER.error(`Failed to upload image(s): ${error}`, error, { uploadedAssets });
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            'Image Upload Error',
            experimentName,
            `Image Upload Error: ${error}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Error',
            eventDetail: `Image Upload Error: ${error}`,
            ...analytics.getPageProperties(),

        });
        setIsError(true);
    }, [LOGGER, analytics, experimentName, experimentVariation,
        handleClearImageSelection, rawExperiments, setPhotoUploadState]);

    const applyMobileUploadedImages = (asset: Gallery.Models.Personalization.PhotoPreviewData): void => {
        dispatch(customImageDataUpdate(asset ? [asset] : [], galleryId));
        dispatch(logoAppliedUpdate(true));
        const startTime = performance.now();

        dispatch(contentUpdate({
            callback: () => {
                trackExperimentEngagement(
                    experimentVariation,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
                    'Image Rerank Complete',
                    experimentName,
                    ((performance.now() - startTime) / 1000).toString(),
                );
            },
        }));

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            'Image Upload Success',
            experimentName,
            `Image Upload Success`,
        );
    };

    const handleUploadStart: UploadManagerProviderProps['onUpload'] = async (selectedAssets, _uploadedBlobs, nonUploadedBlobs) => {
        if (isSmallScreen()) {
            setIsLoadingImage(true);
        } else {
            setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.LOADING });
        }

        setIsError(false);

        // Do nothing if there are no selected assets or there are any non-uploaded files
        if (!selectedAssets.length || nonUploadedBlobs.length) {
            setIsLoadingImage(false);
            return;
        }

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            'Image Upload Started',
            experimentName,
            `Image Upload Started`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Started',
            eventDetail: `Image Upload Started`,
            ...analytics.getPageProperties(),
        });

        /**
         * Wait for all files to finish uploading
         * onError handler could be called for any of these uploads which doesn't result in a thrown error
         */
        const uploadedAssets = (await Promise.all(selectedAssets)).filter((asset) => !!asset) as VistaAsset[];
        const allAssetsUploaded: boolean = uploadedAssets.length === selectedAssets.length;

        /**
         * If not all files resolve with an asset, don't apply any of the uploads as any failed uploads
         * would have triggered an error state.
         */
        if (!allAssetsUploaded) {
            setIsError(true);
            setIsLoadingImage(false);
            return;
        }

        setLocalUploadedAssets(uploadedAssets);

        if (isSmallScreen()) {
            try {
                const preview = await convertVistaAssetToPhotoPreviewData(uploadedAssets[0], 30000);

                mobileProps?.setUnappliedAsset(preview);
            } catch (e) {
                handleUploadError(e as Error, uploadedAssets);
            } finally {
                setIsLoadingImage(false);
            }
        }
    };

    // Add uploaded images to selected images after they finish uploading
    useEffect(() => {
        const convertAndSetPreviews = async (uploadedAssets: VistaAsset[], photosToSelect: number): Promise<void> => {
            const previews = await Promise.all(uploadedAssets.slice(0, photosToSelect).map(
                (asset) => convertVistaAssetToPhotoPreviewData(asset, 30000),
            ));

            setSelectedPhotos([...selectedPhotos, ...previews]);
        };

        if (localUploadedAssets && !isSmallScreen()) {
            try {
                const photosToSelect = isSmallScreen() ? 1 : Math.max(0, MAX_UPLOADS - selectedPhotos.length);

                convertAndSetPreviews(localUploadedAssets, photosToSelect);
            } catch (e) {
                handleUploadError(e as Error, localUploadedAssets);
            } finally {
                setIsLoadingImage(false);
                setPhotoUploadState({ status: PHOTO_UPLOAD_STATES.READY });
                setLocalUploadedAssets(undefined);
            }
        }
    }, [handleUploadError, localUploadedAssets, selectedPhotos, setPhotoUploadState, setSelectedPhotos]);

    const handleMobileSubmitPersonalization = (): void => {
        // updatePersonalizedText() doesn't work for bulk text updates, only single field updates
        const newPersonalizedText: Record<string, string> = {};

        if (mobileProps?.mobileTextfieldValues) {
            Object.keys(mobileProps?.mobileTextfieldValues).forEach((purposeName) => {
                const textfieldValue = mobileProps?.mobileTextfieldValues[purposeName];

                if (textfieldValue !== '') {
                    if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                        const splitName = splitFullName(textfieldValue);

                        newPersonalizedText.firstname = splitName.firstname;
                        newPersonalizedText.lastname = splitName.lastname;
                    }
                    newPersonalizedText[purposeName] = textfieldValue;
                }

                trackExperimentEngagement(
                    experimentVariation,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Text Personalized - Mobile Submit',
                    experimentName,
                    `Text Personalized: ${purposeName}`,
                );
            });
        }

        dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));

        if (mobileProps?.unappliedAsset) {
            applyMobileUploadedImages(mobileProps?.unappliedAsset);

            trackExperimentEngagement(
                experimentVariation,
                rawExperiments,
                analytics,
                ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                'Image Personalized - Mobile Submit',
                experimentName,
                `Image Personalized`,
            );
        } else {
            dispatch(customImageDataUpdate([], galleryId));
            dispatch(logoAppliedUpdate(false));
            dispatch(contentUpdate());
        }

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Submit Personalization Clicked',
            experimentName,
            `Submit Personalization Clicked`,
        );

        mobileProps?.setIsOpen?.(false);
        setTimeout(() => {
            scrollToElement(galleryHeaderId, { behavior: 'auto', block: 'start', inline: 'nearest' });
        }, 100);
    };

    const trackUploadClicked = (): void => {
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Image Upload Clicked',
            experimentName,
            `Image Upload Clicked`,
        );
    };

    const handleAssetDelete = async (asset: VistaAsset): Promise<void> => {
        const previewData = await convertVistaAssetToPhotoPreviewData(asset, 30000);

        // Remove deleted asset from selected photos to prevent selection state from desyncing
        const newPreviews = selectedPhotos.filter((photo) => photo.id !== previewData.id);

        setSelectedPhotos(newPreviews);

        // If applied photos doesn't include the deleted asset, don't do anything
        if (!photoPreviewData.find((photo) => photo.id === previewData.id)) {
            return;
        }

        dispatch(customImageDataUpdate(newPreviews, galleryId));
        dispatch(logoAppliedUpdate(!!newPreviews.length));
        dispatch(contentUpdate());
    };

    useEffect(() => {
        if (!isSmallScreen()) {
            // Even with only one experiment, calling isExperimentActive here will fire the experiment impression
            if (isExperimentActive(PERSONALIZATION_UX_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_PHASE_2_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_PHASE_2_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_HOLIDAY_EXPANSION_EXPERIMENT_NAME);
            } else if (isExperimentActive(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled)) {
                setExperimentVariation(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled);
                setExperimentName(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_EXPERIMENT_NAME);
            }
        } else if (isExperimentActive(PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled)) {
            setExperimentVariation(PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled);
            setExperimentName(PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME);
        }
    }, [isExperimentActive]);

    return (
        <>
            {auth ? (
                <section aria-label="personalize" className="personalization-container">
                    <div />
                    <Box className="personalization-upload-button-container">
                        <UploadConfigurationProvider
                            maxUploads={isSmallScreenPersonalizationEnabled ? 1 : MAX_PHOTO_UPLOADS}
                            supportedFileTypes={basicImageFileTypeMappings.filter((type) => type.fileType !== 'application/pdf')}
                        >
                            <UploadManagerProvider
                                assetsExpireOn={process.env.NODE_ENV === 'production' ? 'never' : 'P1D'}
                                authProvider={uploadAuthProvider}
                                experience="gallery-brand-personalization"
                                subBrand={SubBrands.VistaPrint}
                                writeTenant={process.env.NODE_ENV === 'production' ? Tenants.VistaPrint : Tenants.VistaNonProd}
                                onAssetDeleted={handleAssetDelete}
                                onError={handleUploadError}
                                onUpload={handleUploadStart}
                            >
                                {isSmallScreenPersonalizationEnabled ? (
                                    <Box marginBottom="to-actions">
                                        {isError && (
                                            <DismissableAlertBox
                                                dismissVisuallyHiddenLabel={localize('DismissAlert')}
                                                marginBottom="to-actions"
                                                skin="error"
                                                onRequestDismiss={(): void => setIsError(false)}
                                            >
                                                {localize('PersonalizationImageUploadErrorMessage')}
                                            </DismissableAlertBox>
                                        )}
                                        <PersonalizationImageUploadButton
                                            currentImage={mobileProps?.unappliedAsset}
                                            handleClearImageSelection={handleClearImageSelection}
                                            handleImageError={handleUploadError}
                                            isLoadingImage={isLoadingImage}
                                            isOpen={mobileProps?.isOpen || false}
                                            setIsError={setIsError}
                                            trackUploadClicked={trackUploadClicked}
                                        />
                                    </Box>
                                ) : (
                                    <PersonalizationImageModal
                                        experimentName={experimentName}
                                        experimentVariation={experimentVariation}
                                    />
                                )}
                            </UploadManagerProvider>
                        </UploadConfigurationProvider>
                    </Box>
                    <StandardForm autoComplete="off">
                        {purposeNames.map((p) => (
                            <FormField className="personalization-form-field">
                                <FormInputGroup mb={5}>
                                    {isSmallScreenPersonalizationEnabled && (
                                        <TextInputWithFloatingLabel>
                                            <TextInput
                                                className="personalization-text-input"
                                                placeholder={p.placeholder}
                                                value={mobileProps?.mobileTextfieldValues[p.purposeName]}
                                                onChange={(e): void => handleTextChange(p, e)}
                                                onFocus={(): void => trackTextFocus(p.purposeName)}
                                            />
                                            <TextInputFloatingLabel>
                                                {p.placeholder}
                                            </TextInputFloatingLabel>
                                        </TextInputWithFloatingLabel>
                                    )}
                                    {!isSmallScreenPersonalizationEnabled && (
                                        <TextInputWithFloatingLabel>
                                            <TextInputWithButtonInset>
                                                <TextInput
                                                    className="personalization-text-input"
                                                    placeholder={p.placeholder}
                                                    value={textfieldValues[p.purposeName]}
                                                    onChange={(e): void => handleTextChange(p, e)}
                                                    onFocus={(): void => trackTextFocus(p.purposeName)}
                                                    onKeyDown={(e): void => handleEnterPress(p.purposeName, e)}
                                                />
                                                {textUpdateLoading[p.purposeName] && <Spinner accessibleText={localize('Loading')} className="personalization-text-input-spinner" size="tiny" />}
                                                <TextInputFloatingLabel>
                                                    {p.placeholder}
                                                </TextInputFloatingLabel>
                                            </TextInputWithButtonInset>
                                        </TextInputWithFloatingLabel>
                                    )}
                                </FormInputGroup>
                            </FormField>
                        ))}
                    </StandardForm>
                    {isSmallScreenPersonalizationEnabled
                        && (
                            <>
                                <Divider mb={6} mt={6} />
                                <Button disabled={isLoadingImage} skin="primary" width="full-width" onClick={handleMobileSubmitPersonalization}>
                                    {localize('PersonalizationSubmitButtonText')}
                                </Button>
                            </>
                        )}
                </section>
            ) : (<Box loadingShimmer className="personalization-container-skeleton" />)}
            {/* This doesn't seem to show up */}
            {isError && !isSmallScreenPersonalizationEnabled && (
                <DismissableAlertBox
                    toast
                    className="personalization-image-alert"
                    dismissVisuallyHiddenLabel={localize('DismissAlert')}
                    skin="error"
                    onRequestDismiss={(): void => setIsError(false)}
                >
                    {localize('PersonalizationImageUploadErrorMessage')}
                </DismissableAlertBox>
            )}
        </>
    );
};
