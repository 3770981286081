import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UseGreetingDescriptionResult } from '~/client/hooks/useGreetingDescription/interface';
import { useTranslations } from '~/client/hooks/useTranslations';
import { templateUseCaseByIdSelector } from '~/client/store/filterTemplateUseCases';

export const useGreetingDescription = (tileEntity: State.TileEntity): UseGreetingDescriptionResult => {
    const templateUseCaseById = useSelector(templateUseCaseByIdSelector);
    const localize = useTranslations();
    const [firstTemplateUseCaseId] = Object.keys(tileEntity.availableTemplateUseCasesForExpansion);

    const greetingOptionsArr = useMemo(
        () => tileEntity.availableTemplateUseCasesForExpansion[firstTemplateUseCaseId] || [],
        [tileEntity, firstTemplateUseCaseId],
    );

    const greetingOptions = useMemo(() => greetingOptionsArr.map((option) => templateUseCaseById(option).title).join(', '), [greetingOptionsArr, templateUseCaseById]);

    const shouldShowGreetingOptions = useMemo(() => greetingOptionsArr.length > 1, [greetingOptionsArr]);
    const greetingTitle = useMemo(
        () => templateUseCaseById(firstTemplateUseCaseId)?.title,
        [firstTemplateUseCaseId, templateUseCaseById],
    );

    const formattedGreetingTitle = `${greetingTitle} ${localize('GreetingOptions')}: `;

    return {
        greetingTitle: formattedGreetingTitle,
        greetingOptions,
        shouldShowGreetingOptions,
    };
};
