import { useState } from 'react';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogContent,
    Button,
    Icon,
    ModalDialogNav,
    ModalDialogCloseButton,
    Box,
} from '@vp/swan';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { designPersonalizationContextSelector } from '~/client/store/personalization/selectors';
import { PersonalizationHeader } from 'client/components/Gallery/Filters/PersonalizationFilter/PersonalizationHeader';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationFilter';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { getRawExperiments } from '~/client/store/experimentation';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME, PERSONALIZATION_UX_MOBILE_VARIATIONS } from '~/experiments/tilePersonalization/constants';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { useTranslations } from 'client/hooks/useTranslations';
import { dpcToTextFields } from '../../../Filters/PersonalizationFilter/dpcConverters';
import { usePurposeNames } from '../hooks/usePurposeNames';

export const MobilePersonalization = (): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false);
    const [mobileTextfieldValues, setMobileTextfieldValues] = useState<Record<string, string>>({});
    const [unappliedAsset, setUnappliedAsset] = useState<Gallery.Models.Personalization.PhotoPreviewData>();
    const localize = useTranslations();
    const dpc = useSelector(designPersonalizationContextSelector);
    const purposeNames = usePurposeNames();
    const personalizedText = dpcToTextFields(purposeNames, dpc);
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();

    const resetToTemplateData = (): void => {
        setMobileTextfieldValues(personalizedText);

        if (dpc?.images?.[0].image) {
            const {
                printUrl, previewUrl, width, height,
                // eslint-disable-next-line no-unsafe-optional-chaining
            } = dpc?.images?.[0].image;

            setUnappliedAsset({
                id: '1', thumbnailUrl: previewUrl, printUrl, previewUrl, originalSourceUrl: printUrl, width, height,
            });
        } else {
            setUnappliedAsset(undefined);
        }
    };

    const handleModalOpen = ((): void => {
        setIsOpen(true);
        resetToTemplateData();

        trackExperimentEngagement(
            PERSONALIZATION_UX_MOBILE_VARIATIONS.Enabled,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            'Personalization Button',
            PERSONALIZATION_UX_MOBILE_EXPERIMENT_NAME,
            'Personalization Modal Open',
        );
    });

    const handleModalClose = ((): void => {
        setIsOpen(false);
        resetToTemplateData();
    });

    return (
        <>
            <Button
                aria-labelledby="personalization-button-label"
                buttonShape="round"
                className={classnames('mobile-rounded-icon-button', 'mobile-personalization-button')}
                iconPosition="left"
                paddingX="5"
                skin="primary"
                width="full-width"
                onClick={handleModalOpen}
            >
                {localize('PersonalizationFilterHeader')}
                <Icon className="personalization-button-icon" iconType="magic" />
            </Button>
            <ModalDialog
                className="mobile-personalization-dialog"
                isOpen={isOpen}
                variant="panel-right"
                onRequestDismiss={handleModalClose}
            >
                <ModalDialogContent fullBleed aria-label="mobile-personalization-content">
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText="Close" />
                    </ModalDialogNav>
                    <ModalDialogBody>
                        <Box margin={5} marginTop={6}>
                            <PersonalizationHeader mobileStyle />
                            <PersonalizationFilter
                                mobileProps={{
                                    isOpen,
                                    mobileTextfieldValues,
                                    setIsOpen,
                                    setMobileTextfieldValues,
                                    setUnappliedAsset,
                                    unappliedAsset,
                                }}
                            />
                        </Box>
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

MobilePersonalization.displayName = 'MobilePersonalization';
