import { useMemo } from 'react';
import { useAsyncEffect } from '@design-stack-ct/utility-react';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { convertVistaAssetToPhotoPreviewData } from 'client/components/Gallery/Header/Personalization/utils';
import { PHOTO_UPLOAD_STATES } from 'src/client/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { selectedPhotosState } from 'src/client/atoms/selectedPhotosAtom';
import { photoUploadState } from 'src/client/atoms/photoUploadStateAtom';
import { shouldRestorePhotosState } from 'src/client/atoms/shouldRestorePhotosAtom';
import { isNotFalse } from '~/shared/heplers';
import { customImageDataUpdate, logoAppliedUpdate } from 'client/store/personalization/actions';
import { designPersonalizationContextSelector } from '~/client/store/personalization/selectors';
import { contentUpdate } from '~/client/store/content';

function getAssetIdsFromDpc(dpc: Gallery.Models.Personalization.DesignPersonalizationContext): string[] {
    return dpc?.images?.map((i) => i.image.assetId) ?? [];
}

export const useRestoreImagesFromDpc = (): void => {
    const setUploadState = useSetRecoilState(photoUploadState);
    const setSelectedPhotos = useSetRecoilState(selectedPhotosState);
    const [shouldRestorePhotos, setShouldRestorePhotos] = useRecoilState(shouldRestorePhotosState);

    const { assetStore } = useUploadManager();

    const dispatch = useDispatch();
    const dpc = useSelector(designPersonalizationContextSelector);

    const fetchAndPresignAsset = useMemo(() => async (
        id: string,
    ): Promise<Gallery.Models.Personalization.PhotoPreviewData | undefined> => {
        if (!assetStore) {
            return undefined;
        }

        const asset = await assetStore.fetchSingleAsset({ id });

        await asset.presign();

        return convertVistaAssetToPhotoPreviewData(asset, 30000);
    }, [assetStore]);

    useAsyncEffect(({ runIfMounted }) => {
        const restorePreviewPhotos = async (): Promise<void> => {
            try {
                setUploadState({ status: PHOTO_UPLOAD_STATES.LOADING });

                const storedAssetIds = getAssetIdsFromDpc(dpc);

                if (storedAssetIds.length > 0) {
                    const photoPreviewData = (await Promise.all(storedAssetIds.map(fetchAndPresignAsset)))
                        .filter(isNotFalse);

                    dispatch(customImageDataUpdate(photoPreviewData));
                    dispatch(logoAppliedUpdate(true));
                    dispatch(contentUpdate());
                    setSelectedPhotos(photoPreviewData);
                }
            } finally {
                setUploadState({ status: PHOTO_UPLOAD_STATES.READY });
                setShouldRestorePhotos(false);
            }
        };

        if (shouldRestorePhotos) {
            runIfMounted(restorePreviewPhotos);
        }
    }, [
        setUploadState,
        fetchAndPresignAsset,
        shouldRestorePhotos,
        setSelectedPhotos,
        setShouldRestorePhotos,
    ]);
};
