import { HTMLProps, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    Link,
    Typography,
    Button, Icon,
} from '@vp/swan';

import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { getDebugModeEnabled } from 'client/store/debug';
import { useTranslations } from 'client/hooks/useTranslations';
import { getGalleryIdSelector } from 'client/store/config';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    entityId: string;
}

export const DebugPop = (props: PropTypes): JSX.Element | null => {
    const { entityId } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { enabled } = useSelector(getDebugModeEnabled);
    const galleryId = useSelector(getGalleryIdSelector);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const tileEntity = tileEntityById(entityId);
    const localize = useTranslations();
    const { productKey, productVersion, ...restOfEntity } = tileEntity;
    const debugContent = {
        Gallery: {
            galleryId,
            productKey,
            productVersion,
        },
        Entity: restOfEntity,
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setIsOpen(true);
    };

    return enabled ? (
        <>
            <Button
                iconPosition="right"
                size="mini"
                skin="secondary"
                width="full-width"
                onClick={handleClick}
            >
                Debug
                <Icon iconType="arrowRight" />
            </Button>
            <ModalDialog isOpen={isOpen} onRequestDismiss={(): void => setIsOpen(false)}>
                <ModalDialogContent>
                    <ModalDialogCloseButton visuallyHiddenLabel={localize('FilterMenuCloseButtonText')} />
                    <ModalDialogBody>
                        <Link href={`https://tagging.gallery.vpsvc.com/?ids=${restOfEntity.designId}`} target="_blank">
                            Tagging Tool
                        </Link>
                        <Typography component="pre" fontSize="small">
                            {`"Gallery": ${JSON.stringify(debugContent.Gallery, null, 2)}` }
                        </Typography>
                        <Typography component="pre" fontSize="small">
                            {`"Entity": ${JSON.stringify(debugContent.Entity, null, 2)}` }
                        </Typography>
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </>
    ) : null;
};

DebugPop.displayName = 'DebugPop';
