/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Drawer,
    DrawerProps,
    InfiniteScrollContainer,
    LocalizationProvider,
    UploadDropZone,
    UploadsButton,
    useUploadConfiguration,
    useUploadManager,
} from '@design-stack-vista/upload-components';
import {
    Box,
    Button,
    Divider,
    FlexBox,
    Icon,
    Link,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Typography,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { SignInButton } from '~/client/components/common/SignInButton';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { PHOTO_UPLOAD_STATES } from 'client/constants';
import {
    isAssetUsed, removeAssetFromPhotoPreviews, convertVistaAssetToPhotoPreviewData, convertPhotoPreviewDataToUsedAssets,
    isPhotoPreviewsEqual,
} from 'client/components/Gallery/Header/Personalization/utils';
import { photoUploadState } from 'client/atoms/photoUploadStateAtom';
import { getLogger } from 'client/utils/gallery/logger';
import { useDrawerHandlers, usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/Personalization/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { trackExperimentEngagement } from 'shared/ab-testing';
import { getRawExperiments } from 'client/store/experimentation';
import { photoPreviewDataSelector } from 'client/store/personalization/selectors';
import { PersonalizationImageModalPreviewButton } from './PersonalizationImageModalPreviewButton';
import { MAX_UPLOADS } from './constants';
import { useRestoreImagesFromDpc } from '../../Header/Personalization/hooks/useRestoreImagesFromDpc';
import { useSpecifyImageCopy } from './useSpecifyImageCopy';
import { getLocaleSelector } from '../../../../store/config';
import { PersonalizationAppliedImageIcon } from './PersonalizationAppliedImageIcon';

type PersonalizationModalProps = {
    experimentName: string,
    experimentVariation: string,
};

export const PersonalizationImageModal = (props: PersonalizationModalProps): JSX.Element => {
    const {
        experimentName,
        experimentVariation,
    } = props;

    const { hasAssets } = useUploadManager();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const [selectedPhotos, setSelectedPhotos] = useRecoilState(selectedPhotosState);
    const [uploadState, setPhotoUploadState] = useRecoilState(photoUploadState);
    const photoPreviews = useSelector(photoPreviewDataSelector);
    const usedAssets = useMemo(() => selectedPhotos.map(convertPhotoPreviewDataToUsedAssets), [selectedPhotos]);
    const [uploadModalOpen, setUploadModalOpen] = usePersonalizationFlyoutOpen();
    const specifyImageCopy = useSpecifyImageCopy();
    const locale = useSelector(getLocaleSelector);
    const [extraTitleSpacing, setExtraTitleSpacing] = useState(0);
    const [footerHeight, setFooterHeight] = useState(101);
    const footerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const uploadsButtonRef = useRef<HTMLInputElement>(null);

    const LOGGER = getLogger();

    const isSignedIn = useAuth()?.isSignedIn;
    const localize = useTranslations();
    const {
        supportedFileTypes: { fileExtensionsAsString },
    } = useUploadConfiguration();
    const updatedFileExtensions = fileExtensionsAsString
        .split(', ')
        .filter((ext: string) => ext !== '.pdf')
        .join(', ');

    // Attempt to restore photo previews from browser session
    useRestoreImagesFromDpc();
    /*
     * Workaround for cases where elements within modal have too much height due to copy wrapping to next line
     * in header and footer
     */
    useEffect(() => {
        if (footerRef.current?.clientHeight) {
            setFooterHeight(footerRef.current?.clientHeight);
        }

        if (titleRef.current?.clientHeight) {
            setExtraTitleSpacing(titleRef.current.clientHeight - 33);
        }
    // This useEffect should run when the modal opens so we can get the updated height of elements with a ref
    }, [setFooterHeight, uploadModalOpen]);

    const selectedPhotosAlreadyApplied = useMemo(() => (
        selectedPhotos.length === photoPreviews.length
        && selectedPhotos.every((selectedPhoto, index) => isPhotoPreviewsEqual(selectedPhoto, photoPreviews[index]))
    ), [photoPreviews, selectedPhotos]);

    const disablePhotoPreviewButton = useMemo(
        () => selectedPhotosAlreadyApplied || selectedPhotos.length > MAX_UPLOADS,
        [selectedPhotos.length, selectedPhotosAlreadyApplied],
    );

    const { handleImageLoadStart, handleImageLoadComplete, handleImageLoadError } = useDrawerHandlers();

    const handleDrawerClick: DrawerProps['onClick'] = async (asset, pageNumber) => {
        try {
            const selectedPhotoPreviewData = isAssetUsed(selectedPhotos, asset, pageNumber)
                ? removeAssetFromPhotoPreviews(asset, selectedPhotos, pageNumber)
                // timeout can be 0 here because a visible drawer image implies dimension data is available
                : [...selectedPhotos, await convertVistaAssetToPhotoPreviewData(asset, 0, pageNumber)];

            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization modal image selected',
                eventDetail: `Personalization modal image selected`,
                ...analytics.getPageProperties(),
            });
            setSelectedPhotos(selectedPhotoPreviewData);
        } catch (e) {
            LOGGER.error(`Failed to select photos to preview: ${e}`, e as Error, { asset });
            setPhotoUploadState({
                status: PHOTO_UPLOAD_STATES.ERROR,
                message: localize('PhotoPersonalizationGeneralError'),
            });
        }
    };

    const onPreviewButtonClick = (): void => {
        setUploadModalOpen(true);

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            'Personalization Upload Modal',
            experimentName,
            `Personalization Upload Modal`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            eventLabel: 'Personalization Upload Modal',
            eventDetail: `Personalization Upload Modal`,
            ...analytics.getPageProperties(),

        });
    };

    const handleUploadsClicked = (): void => {
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Image Upload Clicked',
            experimentName,
            `Image Upload Clicked`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Upload Clicked',
            eventDetail: `Image Upload Clicked`,
            ...analytics.getPageProperties(),
        });
    };

    const handleClearSelectedPhotos = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization modal selected images cleared',
            eventDetail: `Personalization modal selected images cleared`,
            ...analytics.getPageProperties(),
        });
        setSelectedPhotos([]);
    };

    const handleDialogClose = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            eventLabel: 'Personalization modal closed',
            eventDetail: `Personalization modal closed`,
            ...analytics.getPageProperties(),
        });
        setSelectedPhotos(photoPreviews);
        setUploadModalOpen(false);
    };

    useEffect(() => {
        if (uploadModalOpen) {
            uploadsButtonRef.current?.focus();
        }
    }, [uploadModalOpen]);

    return (
        <>
            <Button iconPosition="left" width="full-width" onClick={onPreviewButtonClick}>
                {photoPreviews.length ? <PersonalizationAppliedImageIcon objectFit="cover" photoPreviews={photoPreviews} size="28p" /> : <Icon iconType="upload" size="20p" />}
                <span>
                    {photoPreviews.length
                        ? localize(specifyImageCopy('PersonalizationChangeImageButton'))
                        // the locale selector here is a particularly nasty hack that we are using until we can add
                        // context to the gallery copy selectors as the 'add your' copy is too long in only this locale
                        // this should be cleaned up ASAP
                        : localize(specifyImageCopy(locale === 'pt-pt' ? 'PersonalizationAddImageButton' : 'PersonalizationAddYourImageButton'))}
                </span>
            </Button>
            <LocalizationProvider
                localizedValues={{
                    deletingLabel: localize('PhotoPersonalizationImageSelectionAssetDeletingLabel'),
                    deleteConfirmationKeys: {
                        closeButton: 'Close Delete Image Dialog',
                        dialogTitle: localize('PersonalizationModalDeleteImageConfirmationHeader'),
                        dialogMessage: localize('PersonalizationModalDeleteImageConfirmationDescription'),
                        cancelButton: localize('Cancel'),
                        confirmButton: localize('Confirm'),
                    },
                    deleteButtonTitle: localize('PhotoPersonalizationDeleteImageButton'),
                    assetInUseLabel: localize('PhotoPersonalizationImageUsedHoverText'),
                    loadingLabel: localize('PhotoPersonalizationImageSelectionAssetLoadingLabel'),
                    uploadingLabel: localize('PhotoPersonalizationImageSelectionAssetUploadingLabel'),
                }}
            >
                <ModalDialog
                    onlyRenderWhenOpen
                    bodyWidth="capped"
                    className="personalization-image-modal"
                    isOpen={uploadModalOpen}
                    variant="panel-right"
                    onRequestDismiss={handleDialogClose}
                >
                    <ModalDialogContent>
                        <ModalDialogNav mb={0} pb={7}>
                            <ModalDialogCloseButton
                                accessibleText="Close"
                                aria-label={localize('PhotoPersonalizationModalCloseButtonTitle')}
                                visuallyHiddenLabel={localize('PhotoPersonalizationModalCloseButtonTitle')}
                            />
                        </ModalDialogNav>
                        {/* Remove the padding for new Swan VL */}
                        <Box className="personalization-modal-content" px={2}>
                            <ModalDialogHeader mb={7}>
                                <ModalDialogTitle className="personalization-modal-header" fontSkin="title-section" ref={titleRef}>{localize('PersonalizationModalImageHeaderText')}</ModalDialogTitle>
                            </ModalDialogHeader>
                            <ModalDialogBody>
                                <UploadDropZone noClick noKeyboard>
                                    <UploadsButton
                                        multiple
                                        disabled={uploadState.status === PHOTO_UPLOAD_STATES.LOADING}
                                        iconPosition="left"
                                        ref={uploadsButtonRef}
                                        skin={hasAssets && !disablePhotoPreviewButton ? 'secondary' : 'primary'}
                                        width="full-width"
                                        onClick={handleUploadsClicked}
                                    >
                                        <Icon iconType="upload" size="20p" />
                                        <span>{localize('PersonalizationModalImageUploadButtonText')}</span>
                                    </UploadsButton>
                                </UploadDropZone>
                                <Typography className="accepted-formats" fontSize="xsmall" mt="4" textColor="subtle">
                                    {`${localize('PersonalizationModalAcceptedFormats')} ${updatedFileExtensions}`}
                                </Typography>
                                {hasAssets && (
                                    <>
                                        <Divider mt={7} />
                                        <FlexBox
                                            alignItems="center"
                                            justifyContent="space-between"
                                            my={5}
                                        >
                                            <Typography fontSize="small" fontWeight="bold">{localize('PersonalizationModalUploadedAssetsTitle')}</Typography>
                                            <Link
                                                component="button"
                                                fontSize="small"
                                                render={(p): any => (
                                                    <Button
                                                        className={p.className}
                                                        skin="link"
                                                        onClick={handleClearSelectedPhotos}
                                                    >
                                                        {p.children}
                                                    </Button>
                                                )}
                                            >
                                                {localize('ClearSelection')}
                                            </Link>
                                        </FlexBox>
                                        <Box
                                            className={(!isSignedIn)
                                                ? 'personalization-drawer-small-container' : 'personalization-drawer-container'}
                                            mb={6}
                                            style={!isSignedIn ? {
                                                maxHeight: `calc(100vh - 450px - ${footerHeight}px)`,
                                            } : {
                                                maxHeight: `calc(100vh - 450px - ${extraTitleSpacing}px)`,
                                            }}
                                        >
                                            <InfiniteScrollContainer infiniteScrollId="personalization-scroll-list-container">
                                                <Drawer
                                                    deleteOption="confirm"
                                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                                    emptyResultsElement={<></>}
                                                    styleOptions={{ thumbnailSize: 102, flexboxGap: 12 }}
                                                    usedAssets={usedAssets}
                                                    onClick={handleDrawerClick}
                                                    onImageLoadCompleted={handleImageLoadComplete}
                                                    onImageLoadError={handleImageLoadError}
                                                    onImageLoadStart={handleImageLoadStart}
                                                />
                                            </InfiniteScrollContainer>
                                        </Box>
                                        <PersonalizationImageModalPreviewButton disabled={disablePhotoPreviewButton} selectedPhotosAlreadyApplied={selectedPhotosAlreadyApplied} width="full-width" />
                                    </>
                                )}
                            </ModalDialogBody>
                        </Box>
                        {(!isSignedIn) && (
                            <ModalDialogFooter pinned className="personalization-modal-footer" py={5} ref={footerRef}>
                                <Box className="personalization-modal-footer-content" py={4}>
                                    <Typography fontSkin="footnote" mb={5} textAlign="center">{localize('PersonalizationModalLogInDescription')}</Typography>
                                    <SignInButton
                                        isPersonalizationUI
                                        className="personalization-modal-sign-in-button"
                                        component="button"
                                        skin="cta"
                                    />
                                </Box>
                            </ModalDialogFooter>
                        )}
                    </ModalDialogContent>
                </ModalDialog>
            </LocalizationProvider>
        </>
    );
};
