import { UploadsButton } from '@design-stack-vista/upload-components';
import {
    Button, Icon, Spinner,
} from '@vp/swan';
import { useTranslations } from '~/client/hooks/useTranslations';
import { useRef, useEffect } from 'react';
import { useRestoreImagesFromDpc } from '../../Header/Personalization/hooks/useRestoreImagesFromDpc';
import { useSpecifyImageCopy } from './useSpecifyImageCopy';

interface PersonalizationImageButtonTypes {
    handleClearImageSelection: () => void,
    setIsError: (isError: boolean) => void,
    handleImageError: () => void,
    trackUploadClicked: () => void,
    currentImage?: Gallery.Models.Personalization.PhotoPreviewData,
    isLoadingImage: boolean,
    isOpen: boolean,
}

export const PersonalizationImageUploadButton = (props: PersonalizationImageButtonTypes): JSX.Element => {
    const {
        handleClearImageSelection,
        setIsError,
        handleImageError,
        trackUploadClicked,
        currentImage,
        isLoadingImage,
        isOpen,
    } = props;

    const localize = useTranslations();
    // Attempt to restore photo previews from browser session

    useRestoreImagesFromDpc();
    const specifyImageCopy = useSpecifyImageCopy();
    const uploadsButtonRef = useRef<HTMLInputElement>(null);

    // have to delay this slightly as SWAN will automatically attempt to focus the close button
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => uploadsButtonRef.current?.focus(), 100);
        }
    }, [isOpen]);

    if (currentImage) {
        return (
            <div>
                <Button
                    iconPosition="left"
                    ref={uploadsButtonRef}
                    width="full-width"
                    onClick={(): void => {
                        handleClearImageSelection();
                        setIsError(false);
                    }}
                >
                    {/* TODO: use the PersonalizationAppliedImageIcon */}
                    <Icon src={currentImage.thumbnailUrl} onError={handleImageError} />
                    {localize(specifyImageCopy('PersonalizationRemoveImageButton'))}
                </Button>
            </div>
        );
    }

    return (
        <UploadsButton iconPosition="left" id="personalization-upload-button" ref={uploadsButtonRef} width="full-width" onClick={trackUploadClicked}>
            {isLoadingImage && (
                <>
                    <Spinner accessibleText={localize('Loading')} />
                    <span>{localize('PersonalizationUploadingLabel')}</span>
                </>
            )}
            {!isLoadingImage && (
                <>

                    <Icon iconType="image" size="20p" />
                    <span>{localize(specifyImageCopy('PersonalizationAddYourImageButton'))}</span>
                </>
            )}
        </UploadsButton>
    );
};

PersonalizationImageUploadButton.displayName = 'PersonalizationImageUploadButton';
